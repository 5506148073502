<template>
	<div class="px-4 bg-slate-800 self-stretch z-[1] flex items-center">
		<div class="flex items-center border-collapse border-slate-600 my-2 -mx-3">
			<NuxtLink
				v-for="(item, i) in footerItems"
				:key="i"
				:to="item.link"
				class="text-white text-xs py-0.5 hover:text-primary duration-300 border-e last:border-none border-slate-600 px-3"
			>
				{{ item.title }}
			</NuxtLink>
		</div>
		<div class="flex-grow" />
		<span class="text-xs text-white text-opacity-50">
			{{ runtimeConfig.public.version }}
		</span>
	</div>
</template>

<script setup lang="ts">
const app = useNuxtApp()
const runtimeConfig = useRuntimeConfig()

const footerItems = computed(() => [
	{
		id: "terms",
		title: app.$i18n.t("terms_of_service"),
		link: app.$routeUrl.TermsOfServiceUrl()
	},
	{
		id: "privacy",
		title: app.$i18n.t("privacy_policy"),
		link: app.$routeUrl.PrivacyPolicyUrl()
	}
])
</script>
